import { parseTime } from "@/utils/util";
import store from "@/store";
var OSSConfig = store.state.user.OSSConfig;
store.dispatch("getOss").then((data) => {
  OSSConfig = data;
});
const OSS = require("ali-oss");

const OssBizPathConfig = {
  trend: parseTime(new Date(), "{y}{m}") + "/trend/",
  video: parseTime(new Date(), "{y}{m}") + "/video/",
};

// endpoint: https://dongfang-yz-shanghai.oss-cn-shanghai.aliyuncs.com/

/**
 * 配置跨域共享
 * @returns {Promise<void>}
 */
export async function configCors(client) {
  const rules = [
    {
      // 指定允许跨域请求的来源，支持通配符星号（*），表示允许所有的来源域。
      // allowedOrigin: 'http://example.com',
      allowedOrigin: "*",
      // 指定允许的跨域请求方法，支持GET、PUT、DELETE、POST和HEAD方法。
      allowedMethod: "*",
      // 指定允许跨域请求的响应头。建议无特殊情况下将此项设置为通配符星号（*）。
      allowedHeader: "*",
      // 指定允许用户从应用程序中访问的响应头，例如一个JavaScript的XMLHttpRequest对象。不允许使用通配符星号（*）。
      exposeHeader: "Content-Length",
      // 指定浏览器对特定资源的预取（OPTIONS）请求返回结果的缓存时间，单位为秒。
      maxAgeSeconds: "30",
    },
  ];
  try {
    const result = await client.putBucketCORS(OSSConfig.bucket, rules);
    console.log("配置跨域共享", result);
  } catch (err) {
    console.log(err);
  }
}

/**
 * 设置防盗链
 * @param client
 * @param refererList referer地址 eg:['example.com','*.example.com']
 * @returns {Promise<void>}
 */
export async function configReferer(client, refererList) {
  try {
    const result = await client.putBucketReferer(
      OSSConfig.bucket,
      true,
      refererList
    );
    console.log("设置防盗链", result);
  } catch (err) {
    console.log(err);
  }
}

/**
 * 查看存储空间列表
 * @returns {Promise<void>}
 */
export async function listBuckets(client) {
  try {
    const result = await client.listBuckets();
    console.log("查看存储空间列表", result);
  } catch (err) {
    console.log(err);
  }
}

/**
 * 查看文件列表
 * @returns {Promise<void>}
 */
export async function list(client) {
  // 不带任何参数，默认最多返回100个文件。
  const result = await client.list();
  console.log("查看文件列表", result);
}

const progress = (p, _checkpoint) => {
  // Object的上传进度。
  console.log("Object的上传进度", p);
  // 分片上传的断点信息。
  console.log("分片上传的断点信息", _checkpoint);
};

/**
 * 删除文件
 *
 * @param targetFilePath
 * @returns {Promise<unknown>}
 */
export function deleteFile(targetFilePath) {
  return new Promise((resolve, reject) => {
    try {
      const client = new OSS(OSSConfig);
      // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
      client
        .delete(targetFilePath)
        .then((result) => {
          console.log("删除文件", result);
        })
        .catch((err) => {
          console.log("删除文件", err);
          reject(err);
        });
    } catch (e) {
      console.log("删除文件", e);
      reject(e);
    }
  });
}

/**
 * 上传文件
 * @param params
 * @returns {Promise<unknown>}
 */
export async function uploadFile(params) {
  return new Promise((resolve, reject) => {
    let data;
    const { bizType, type, fileName, targetName, file } = params;
    const size = file.size;
    switch (type) {
      case "file":
        data = file;
        break;
      case "blob":
        data = new Blob(file);
        break;
      case "buffer":
        data = new OSS.Buffer(file);
        break;
    }
    if (!data) {
      reject(new Error("未指定文件type:[file|blob|buffer]"));
      return;
    }
    const targetDir = OssBizPathConfig[bizType];
    if (!targetDir) {
      reject(new Error("未配置上传路径"));
      return;
    }

    try {
      const client = new OSS(OSSConfig);
      // 配置跨域
      // configCors(client)
      const targetFilePath = targetDir + targetName;

      // debugger
      const a = 10;
      if (a === 1) {
        resolve({
          fileName: fileName,
          name: "202209/video/1664030711879_test-video.mp4",
          url: "http://dongfang-yz-shanghai.oss-cn-shanghai.aliyuncs.com/202209/video//1664030711879_test-video.mp4",
          res: {
            aborted: false,
            requestUrls: [
              "http://dongfang-yz-shanghai.oss-cn-shanghai.aliyuncs.com/202209/video//1664030711879_test-video.mp4",
            ],
          },
        });
        return;
      }

      // 填写OSS文件完整路径和本地文件的完整路径。OSS文件完整路径中不能包含Bucket名称。
      // 如果本地文件的完整路径中未指定本地路径，则默认从示例程序所属项目对应本地路径中上传文件。
      // 小于100M的默认上传，否则断点上传
      if (size / 1024 / 1024 <= 100) {
        client.put(targetFilePath, data).then((result) => {
          result = { ...result, fileName: fileName };
          console.log("OSS上传普通文件结果", result);
          resolve(result);
        });
      } else {
        client
          .multipartUpload(targetFilePath, data, { progress })
          .then((result) => {
            // , {headers}
            result = { ...result, fileName: fileName };
            console.log("OSS上传大文件结果", result);
            resolve(result);
          });
      }
    } catch (e) {
      console.log("OSS上传文件异常", e);
      reject(e);
    }
  });
}

/**
 * 上传url图片
 * @param targetFilePath 目标地址
 * @param firstFramePath 首帧url地址
 *
 */
export async function uploadFromUrl(targetFilePath, firstFramePath) {
  try {
    // 填写Object完整路径和本地文件的完整路径。Object完整路径中不能包含Bucket名称。
    // 如果指定的本地文件存在会覆盖，不存在则新建。
    // 如果未指定本地路径，则下载后的文件默认保存到示例程序所属项目对应本地路径中。
    const client = new OSS(OSSConfig);
    const result = await client.get(targetFilePath);
    console.log("OSS下载文件", result);
  } catch (e) {
    console.log(e);
  }
}

/**
 * 下载文件
 */
export async function get(targetFilePath) {
  try {
    // 填写Object完整路径和本地文件的完整路径。Object完整路径中不能包含Bucket名称。
    // 如果指定的本地文件存在会覆盖，不存在则新建。
    // 如果未指定本地路径，则下载后的文件默认保存到示例程序所属项目对应本地路径中。
    const client = new OSS(OSSConfig);
    const result = await client.get(targetFilePath);
    console.log("OSS下载文件", result);
  } catch (e) {
    console.log(e);
  }
}

/**
 * ajax 请求获取base64格式
 * @param {String}    url 需要请求的图片的连接
 * @return {Promise}  promise对象
 */
const getBase = (url) => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.blob()) // 将响应体转换成blob格式数据
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      })
      .catch(console.error);
  });
};

/**
 * base64转化为对应的文件
 * @param {String}  base  base64字符
 * @param {String}  name  文件名称
 * @return: {file} 返回的文件
 */
const base64toFile = (base, filename) => {
  const arr = base.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  // 转换成file对象
  return new File([u8arr], filename, {
    type: mime,
  });
};

/**
 * 图片转化为文件类型
 * @param {String} url  请求图片的地址
 * @param {String} name 转换后的文件名称
 * @return: {file}
 */
export async function imgUrlToFile(url, name) {
  let fileObj = {};
  await getBase(url).then((base) => {
    fileObj = base64toFile(base, name);
  });
  return fileObj;
}

export async function getImageFileFromUrl(url, imageName) {
  return new Promise((resolve, reject) => {
    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.setRequestHeader("Accept", "image/jpeg");
    xhr.responseType = "blob";
    xhr.onload = () => {
      blob = xhr.response;
      const imgFile = new File([blob], imageName, { type: "image/jpeg" });
      resolve(imgFile);
    };
    xhr.onerror = (e) => {
      reject(e);
    };
    xhr.send();
  });
}
