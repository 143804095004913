<template>
  <ak-container type="white" class="header-info-main" card :bodyStyle="{ padding: 0 }">
    <!-- <a-row :gutter="gutter" class="MB16" v-if="businessResp !== '{}'">
      <a-col :span="14">
        <a-card class="left-detail-card">
          <a-row>
            <a-col :span="4">
              <img class="logo" v-if="businessResp.logo!==''" :src="`${$const.OssImagePrefix}/${businessResp.logo}`" />
              <img class="logo" v-else src="../../assets/common_emty.png" />
            </a-col>
            <a-col :span="12">
              {{ businessResp.shopName || '未知' }}
              <br />
              联系方式：{{ personalResp.phone || '暂无' }}
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="4">描述</a-col>
            <a-col :span="20">
              {{ businessResp.describe || '暂无描述' }}
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="4">数据</a-col>
            <a-col :span="20">
              粉丝{{ businessResp.fansNum || 0 }} 关注{{ businessResp.follow || 0 }}
              获赞与收藏{{ businessResp.favorite || 0 }}
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="4">最后IP属地</a-col>
            <a-col :span="20">
              {{ businessResp.ipLocation || '暂无' }}
            </a-col>
          </a-row>
        </a-card>
      </a-col>
      <a-col :span="10">
        <a-card class="left-detail-card">
          <div class="F18 F-BOLD" style="font-weight: bold; margin-bottom: 16px">
            账户信息
          </div>
          <a-row>
            <a-col :span="8">状态</a-col>
            <a-col :span="16">
              {{ statusAry[businessResp.state] }}
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">{{ businessResp.businessTag === 1 ? '个人认证' : '企业真实性认证' }}</a-col>
            <a-col :span="16">
              {{ statusRealAry[businessResp.businessReal] }}
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">最后登录时间</a-col>
            <a-col :span="16">
              {{ businessResp.lastLoginTime }}
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">注册时间</a-col>
            <a-col :span="16">
              {{ businessResp.createdTime }}
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row> -->

    <!--绑定用户-->
    <a-card style="width: 100%;margin-top: 15px;">
      <div class="F16 F-BOLD" style="font-weight: bold;margin-bottom: 10px;">绑定用户</div>
      <div class="flex-start">
        <div style="width: 60%;margin-right: 15px;">
          <div class="flex-start margin-bottom">
            <img :src="personalResp.headImage" style="width: 60px;height: 60px;margin-right: 15px;" alt="">
            <div>
              {{ personalResp.name || '未知' }}<br>{{ sexAry[personalResp.sex] }}<br>{{ personalResp.phone || '暂无' }}
            </div>
          </div>
          <div class="flex-start margin-bottom">
            <span class="label">生日</span>
            <span class="text">{{ personalResp.birthday || '未知' }}</span>
          </div>
          <div class="flex-start margin-bottom">
            <span class="label">兴趣</span>
            <span class="text">{{ getstr(personalResp.userTabs) }}</span>
          </div>
          <div class="flex-start margin-bottom">
            <span class="label">数据</span>
            <span class="text">喜欢{{ personalResp.like || 0 }} 收藏{{ personalResp.favorite || 0 }}
              关注{{ personalResp.follow || 0 }} </span>
          </div>
          <div class="flex-start">
            <span class="label">最后IP属地</span>
            <span class="text">{{ personalResp.ipLocation || '未知' }}</span>
          </div>
        </div>
        <div style="flex:1;padding: 24px;">
          <div class="flex-start margin-bottom">
            <span class="label">状态</span>
            <span class="text">{{ statusAry[personalResp.state] }}</span>
          </div>
          <div class="flex-start margin-bottom" style="display:none">
            <span class="label">实名认证</span>
            <span class="text">{{ realNameAry[personalResp.real] }}</span>
          </div>
          <div class="flex-start margin-bottom">
            <span class="label">最后登录时间</span>
            <span class="text">{{ personalResp.lastLoginTime }}</span>
          </div>
          <div class="flex-start">
            <span class="label">注册时间</span>
            <span class="text">{{ personalResp.createdTime }}</span>
          </div>
        </div>
      </div>
    </a-card>
  </ak-container>
</template>

<script>
export default {
  props: {
    businessResp: {
      type: Object,
      default: () => {
        return { };
      }
    },
    personalResp: {
      type: Object,
      default: () => {
        return { };
      }
    }
  },
  data() {
    return {
      gutter: 24,
      statusAry: ['禁用', '正常'],
      statusRealAry: ['未提交认证', '已认证', '真实性认证待审核'],
      realNameAry: ['未提交实名认证', '已实名认证', '实名认证待审核'],
      sexAry: ['女', '男']
    }
  },
  methods: {
    getstr(ary) {
      if (ary && ary.length > 0) {
        return ary.join('、');
      }
      return '暂无';
    }
  }
}
</script>
<style>
  .create-bk-card {
    margin: 20px 0 20px 0;
  }
  .bussiness {
    margin-top: 20px;
  }

  .fileList .ak-viewer-wrap .preview-thumbnail-list .list-item img {
    width: 300px !important;
    height: 400px !important;
  }
</style>
<style lang="less" scoped>
  .common-detail-view {
    .header-info-main {
      padding: 20px;

      .C666 {
        display: inline-block;
        width: 90px;
        text-align: right;
      }
    }
  }

  .left-detail-card {
    width: 100%;
  }

  /deep/ .bindUser .ant-card-body {
    padding: 12px 0 6px 24px;
  }

  .user-info {
    margin-top: 20px;

    .user-info-content {
      padding: 20px;

      .user-info-certification {
        font-weight: bold;
        margin-bottom: 16px;
      }
    }
  }

  .ant-col {
    margin-bottom: 8px;
  }

  .ant-col:last-child {
    margin-bottom: 0;
  }

  .flex-start {
    display: flex;
    justify-content: flex-start;
  }

  .margin-bottom {
    margin-bottom: 20px;
  }

  .text {
    flex: 1;
  }

  // .ant-row{
  //   margin-bottom: 8px;
  // }
  .ant-row:last-child {
    margin-bottom: 0;
  }
  .label{width: 90px;}
</style>
