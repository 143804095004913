<template>
  <div class="container">
    <div style="position: absolute; top: 65px; right: 20px">
      <a-button type="primary" class="ML12" @click="submit">确认</a-button>
      <a-button class="ML12" @click="$router.back()">取消</a-button>
    </div>

    <DetailTop :businessResp="businessResp" :personalResp="personalResp" />

    <a-form-model
      ref="form"
      :rules="rules"
      style="margin-top: 20px"
      :model="formData"
      :colon="false"
    >
      <!--实名认证-->
      <template title="真实姓名" v-if="authType === '1'">
        <a-form-model-item
          label="真实姓名"
          prop="realName"
          style="width: 500px"
        >
          <a-input
            allowClear
            v-model="formData.realName"
            placeholder="请输入"
          />
        </a-form-model-item>
        <div
          class="flex-start"
          style="width: 850px; margin-bottom: 15px; flex-wrap: wrap"
        >
          <a-form-model-item prop="handIdentityCard">
            <a-upload
              style="margin: 0 15px 15px 0"
              name="file"
              :multiple="false"
              :before-upload="handleBeforeUpload"
              @change="handleChangeIdCard"
              :customRequest="handleCustomUpload"
              :showUploadList="false"
            >
              <img
                v-if="idCards.handIdentityCard"
                :src="`${$const.OssImagePrefix}/${idCards.handIdentityCard}`"
                class="upload-btn-02"
              />
              <a-button v-else class="upload-btn-02"
                >点击上传手持身份证图片</a-button
              >
            </a-upload>
          </a-form-model-item>

          <a-form-model-item prop="frontIdentityCard">
            <a-upload
              style="margin: 0 15px 15px 0"
              name="file"
              :multiple="false"
              :before-upload="handleBeforeUpload"
              @change="handleChangePositive"
              :customRequest="handleCustomUpload"
              :showUploadList="false"
            >
              <img
                v-if="idCards.frontIdentityCard"
                :src="`${$const.OssImagePrefix}/${idCards.frontIdentityCard}`"
                class="upload-btn-02"
              />
              <a-button v-else class="upload-btn-02"
                >点击上传身份证正面图片</a-button
              >
            </a-upload>
          </a-form-model-item>

          <a-form-model-item prop="backIdentityCard">
            <a-upload
              style="margin: 0 15px 15px 0"
              name="file"
              :multiple="false"
              :before-upload="handleBeforeUpload"
              @change="handleChangeOther"
              :customRequest="handleCustomUpload"
              :showUploadList="false"
            >
              <img
                v-if="idCards.backIdentityCard"
                :src="`${$const.OssImagePrefix}/${idCards.backIdentityCard}`"
                class="upload-btn-02"
              />
              <a-button v-else class="upload-btn-02"
                >点击上传身份证反面图片</a-button
              >
            </a-upload>
          </a-form-model-item>
        </div>
      </template>
      <template title="品牌故事" v-if="authType === '4'">
        <a-form-model-item
          label="品牌故事认证"
          prop="shopBrief"
          style="width: 500px"
        >
          <a-textarea
            allowClear
            v-model="formData.shopBrief"
            placeholder="品牌故事不超过2000字符,含标点和空格"
            show-count
            :maxlength="2000"
            :auto-size="{ minRows: 6 }"
          />
        </a-form-model-item>
      </template>
      <template title="主页名称认证" v-if="authType === '14'">
        <a-form-model-item
          label="主页名称认证"
          prop="shopName"
          style="width: 300px"
        >
          <a-input v-model="formData.shopName" placeholder="请输入主页名称" />
        </a-form-model-item>
      </template>
      <template title="简介认证" v-if="authType === '15'">
        <a-form-model-item label="简介认证" prop="intro" style="width: 500px">
          <a-textarea
            allowClear
            v-model="formData.intro"
            placeholder="简介不超过100字符,含标点和空格"
            show-count
            :maxlength="100"
            :auto-size="{ minRows: 6 }"
            @input="limitInput"
          />
        </a-form-model-item>
      </template>

      <template title="品牌认证" v-if="authType === '5'">
        <a-form-model-item
          label="品牌认证"
          prop="brandName"
          style="width: 500px"
        >
          <a-input
            allowClear
            v-model="formData.brandName"
            placeholder="请输入商标名称"
          />
        </a-form-model-item>
        <p><span>商标注册证</span></p>
        <div
          class="flex-start"
          style="width: 900px; margin-bottom: 15px; flex-wrap: wrap"
        >
          <a-upload
            style="margin: 0 15px 15px 0"
            name="file"
            :multiple="false"
            :before-upload="handleBeforeUpload"
            @change="handleBrandRegistPic"
            :customRequest="handleCustomUpload"
            :showUploadList="false"
          >
            <img
              v-if="brandImages.brandRegistPic"
              :src="`${$const.OssImagePrefix}/${brandImages.brandRegistPic}`"
              class="upload-btn"
            />
            <a-button v-else class="upload-btn">点击上传商标注册证图</a-button>
          </a-upload>
          <a-upload
            style="margin: 0 15px 15px 0"
            name="file"
            :multiple="false"
            :before-upload="handleBeforeUpload"
            @change="handleBrandLogoPic"
            :customRequest="handleCustomUpload"
            :showUploadList="false"
          >
            <img
              v-if="brandImages.brandLogoPic"
              :src="`${$const.OssImagePrefix}/${brandImages.brandLogoPic}`"
              class="upload-btn"
            />
            <a-button v-else class="upload-btn">点击上传商标LOGO图</a-button>
          </a-upload>
        </div>
        <p><span>商标授权证</span></p>
        <div
          class="flex-start"
          style="width: 900px; margin-bottom: 15px; flex-wrap: wrap"
        >
          <a-upload
            style="margin: 0 15px 15px 0"
            name="file"
            :multiple="false"
            :before-upload="handleBeforeUpload"
            @change="handleBrandAuthorizePic"
            :customRequest="handleCustomUpload"
            :showUploadList="false"
          >
            <img
              v-if="brandImages.brandAuthorizePic"
              :src="`${$const.OssImagePrefix}/${brandImages.brandAuthorizePic}`"
              class="upload-btn"
            />
            <a-button v-else class="upload-btn">点击上传商标授权书</a-button>
          </a-upload>
        </div>
      </template>
      <template title="经营地址认证" v-if="authType === '6'">
        <a-form-model-item
          label="经营地址认证"
          prop="shopAdressDetail"
          style="width: 500px"
        >
          <a-input
            allowClear
            v-model="formData.shopAdressDetail"
            placeholder="请输入经营地址"
          />
        </a-form-model-item>
        <div class="justify-start">
          <p style="margin-right: 15px"><span>工作时间</span></p>
          <div class="flex-column">
            <div style="display: flex; width: 250px">
              <a-select
                ref="select"
                v-model="formData.workDaysBegin"
                style="flex: 1"
                @focus="focus"
              >
                <a-select-option value="周一">周一</a-select-option>
                <a-select-option value="周二">周二</a-select-option>
                <a-select-option value="周三">周三</a-select-option>
                <a-select-option value="周四">周四</a-select-option>
                <a-select-option value="周五">周五</a-select-option>
                <a-select-option value="周六">周六</a-select-option>
                <a-select-option value="周日">周日</a-select-option>
              </a-select>
              <div style="width: 30px; text-align: center">~</div>
              <a-select
                ref="select"
                v-model="formData.workDaysEnd"
                style="flex: 1"
                @focus="focus"
              >
                <a-select-option value="周一">周一</a-select-option>
                <a-select-option value="周二">周二</a-select-option>
                <a-select-option value="周三">周三</a-select-option>
                <a-select-option value="周四">周四</a-select-option>
                <a-select-option value="周五">周五</a-select-option>
                <a-select-option value="周六">周六</a-select-option>
                <a-select-option value="周日">周日</a-select-option>
              </a-select>
            </div>
            <div style="display: flex; width: 250px; margin-top: 10px">
              <a-time-picker
                style="flex:1:"
                format="HH:mm"
                valueFormat="HH:mm"
                :minuteStep="1"
                v-model="formData.workTimeBegin"
              />
              <div style="width: 30px; text-align: center">~</div>
              <a-time-picker
                style="flex:1:"
                format="HH:mm"
                valueFormat="HH:mm"
                :minuteStep="1"
                v-model="formData.workTimeEnd"
              />
            </div>
          </div>
        </div>
        <p><span>选择地图上的定位</span></p>
        <div
          class="flex-start"
          style="width: 900px; margin-bottom: 15px; flex-wrap: wrap"
        >
          <div>
            <txMap
              ref="txMap"
              v-if="isaddrobj"
              :lng="addrobj.lng || 0"
              :lat="addrobj.lat || 0"
              @end="handleMap"
            >
            </txMap>
          </div>
        </div>
        <p><span>上传经营地址签约合同图</span></p>
        <div
          class="flex-start"
          style="width: 900px; margin-bottom: 15px; flex-wrap: wrap"
        >
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div
              class="flex-start"
              style="width: 900px; margin-bottom: 15px; flex-wrap: wrap"
            >
              <a-input
                style="width: 280px; margin-right: 15px; display: none"
                allowClear
                v-model="authItem.title"
                placeholder="请输入"
              />
            </div>
            <div
              class="flex-start"
              style="width: 900px; margin-bottom: 15px; flex-wrap: wrap"
            >
              <div
                v-for="(img, idx) in authItem.imgs"
                :key="img"
                class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0"
              >
                <img
                  v-if="img"
                  :src="`${$const.OssImagePrefix}/${img}`"
                  class="upload-btn-02"
                />
                <img
                  src="@/assets/delete.png"
                  class="img-delete"
                  @click="handleRemoveImage(index, idx)"
                />
              </div>
              <a-upload
                style="margin: 0 15px 15px 0"
                name="file"
                :multiple="false"
                :before-upload="handleBeforeUpload"
                @change="(e) => handleAuthImage(e, index)"
                :customRequest="handleCustomUpload"
                :showUploadList="false"
                v-if="!authItem.imgs || authItem.imgs.length < 100"
              >
                <a-button class="upload-btn-02">经营地址签约合同图</a-button>
              </a-upload>
            </div>
          </div>
        </div>
      </template>
      <template title="办公环境认证" v-if="authType === '9'">
        <a-form-model-item label="办公环境认证">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div
              class="flex-start"
              style="
                width: 900px;
                margin-bottom: 15px;
                flex-wrap: wrap;
                display: none;
              "
            >
              <a-input
                style="width: 290px; margin-right: 15px"
                allowClear
                v-model="authItem.title"
                placeholder="请输入"
              />
            </div>
            <div
              class="flex-start"
              style="width: 900px; margin-bottom: 15px; flex-wrap: wrap"
            >
              <div
                v-for="(img, idx) in authItem.imgs"
                :key="img"
                class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0"
              >
                <img
                  v-if="img"
                  :src="`${$const.OssImagePrefix}/${img}`"
                  class="upload-btn-02"
                />
                <img
                  src="@/assets/delete.png"
                  class="img-delete"
                  @click="handleRemoveImage(index, idx)"
                />
              </div>
              <a-upload
                style="margin: 0 15px 15px 0"
                name="file"
                :multiple="false"
                :max-count="100"
                :before-upload="handleBeforeUpload"
                @change="(e) => handleAuthImage(e, index)"
                :customRequest="handleCustomUpload"
                :showUploadList="false"
                v-if="!authItem.imgs || authItem.imgs.length < 100"
              >
                <a-button class="upload-btn-02">办公室环境图</a-button>
              </a-upload>
            </div>
          </div>
        </a-form-model-item>
      </template>
      <template title="身份认证" v-if="authType === '2'">
        <a-form-model-item label="身份认证">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div
              class="flex-start"
              style="width: 900px; margin-bottom: 15px; flex-wrap: wrap"
            >
              <a-input
                style="width: 290px; margin-right: 15px"
                allowClear
                v-model="authItem.title"
                placeholder="例:米兰国际设计师,擅长原作/国风"
              />
            </div>
            <div
              class="flex-start"
              style="width: 900px; margin-bottom: 15px; flex-wrap: wrap"
            >
              <div
                v-for="(img, idx) in authItem.imgs"
                :key="img"
                class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0"
              >
                <img
                  v-if="img"
                  :src="`${$const.OssImagePrefix}/${img}`"
                  class="upload-btn-02"
                />
                <img
                  src="@/assets/delete.png"
                  class="img-delete"
                  @click="handleRemoveImage(index, idx)"
                />
              </div>
              <a-upload
                style="margin: 0 15px 15px 0"
                name="file"
                :multiple="false"
                :before-upload="handleBeforeUpload"
                @change="(e) => handleAuthImage(e, index)"
                :customRequest="handleCustomUpload"
                :showUploadList="false"
                v-if="!authItem.imgs || authItem.imgs.length < 100"
              >
                <a-button class="upload-btn-02">认证图片</a-button>
              </a-upload>
            </div>
          </div>
        </a-form-model-item>
      </template>
      <template title="经历认证" v-if="authType === '12'">
        <a-form-model-item label="经历认证" prop="shopName">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div
              class="flex-start"
              style="width: 900px; margin-bottom: 15px; flex-wrap: wrap"
            >
              <a-date-picker
                mode="year"
                placeholder="请选择开始年份"
                format="YYYY"
                style="width: 160px; margin-right: 10px"
                :value="authItem.startShowTime || null"
                :open="isOpen === `${index}-${'startTime'}`"
                @change="(e) => onChange(e, index)"
                @openChange="(e) => openChange(e, 'startTime', index)"
                @panelChange="
                  (e) => panelChange(e, 'startTime', authItem, index)
                "
              />
              <a-date-picker
                mode="year"
                placeholder="请选择结束年份"
                format="YYYY"
                style="width: 160px; margin-right: 10px"
                :value="authItem.endShowTime || null"
                :open="isOpen === `${index}-${'endTime'}`"
                @change="(e) => onChange(e, index)"
                @openChange="(e) => openChange(e, 'endTime', index)"
                @panelChange="(e) => panelChange(e, 'endTime', authItem, index)"
              />
              <a-input
                style="width: 400px"
                allowClear
                v-model="authItem.title"
                placeholder="请输入"
              />
            </div>
            <div
              class="flex-start"
              style="width: 900px; margin-bottom: 15px; flex-wrap: wrap"
            >
              <div
                v-for="(img, idx) in authItem.imgs"
                :key="img"
                class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0"
              >
                <img
                  v-if="img"
                  :src="`${$const.OssImagePrefix}/${img}`"
                  class="upload-btn-02"
                />
                <img
                  src="@/assets/delete.png"
                  class="img-delete"
                  @click="handleRemoveImage(index, idx)"
                />
              </div>
              <a-upload
                style="margin: 0 15px 15px 0"
                name="file"
                :multiple="false"
                :before-upload="handleBeforeUpload"
                @change="(e) => handleAuthImage(e, index)"
                :customRequest="handleCustomUpload"
                :showUploadList="false"
                v-if="!authItem.imgs || authItem.imgs.length < 100"
              >
                <a-button class="upload-btn-02">认证图片</a-button>
              </a-upload>
            </div>
          </div>
        </a-form-model-item>
        <a-button type="primary" class="ML12" @click="handleAddAuth"
          >新增经历</a-button
        >
      </template>
      <template title="获奖认证" v-if="authType === '13'">
        <a-form-model-item label="获奖认证" prop="shopName">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div
              class="flex-start"
              style="width: 900px; margin-bottom: 15px; flex-wrap: wrap"
            >
              <a-input
                style="width: 280px; margin-right: 15px"
                allowClear
                v-model="authItem.title"
                placeholder="请输入"
                value="2023-2024"
              />
            </div>
            <div
              class="flex-start"
              style="width: 900px; margin-bottom: 15px; flex-wrap: wrap"
            >
              <div
                v-for="(img, idx) in authItem.imgs"
                :key="img"
                class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0"
              >
                <img
                  v-if="img"
                  :src="`${$const.OssImagePrefix}/${img}`"
                  class="upload-btn-02"
                />
                <img
                  src="@/assets/delete.png"
                  class="img-delete"
                  @click="handleRemoveImage(index, idx)"
                />
              </div>
              <a-upload
                style="margin: 0 15px 15px 0"
                name="file"
                :multiple="false"
                :before-upload="handleBeforeUpload"
                @change="(e) => handleAuthImage(e, index)"
                :customRequest="handleCustomUpload"
                :showUploadList="false"
                v-if="!authItem.imgs || authItem.imgs.length < 100"
              >
                <a-button class="upload-btn-02">认证图片</a-button>
              </a-upload>
            </div>
          </div>
        </a-form-model-item>
        <a-button type="primary" class="ML12" @click="handleAddAuth"
          >新增获奖</a-button
        >
      </template>
      <template title="展览认证" v-if="authType === '11'">
        <a-form-model-item label="展览认证" prop="shopName">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div
              class="flex-start"
              style="width: 900px; margin-bottom: 15px; flex-wrap: wrap"
            >
              <a-date-picker
                mode="year"
                placeholder="请选择开始年份"
                format="YYYY"
                style="width: 160px; margin-right: 10px"
                :value="authItem.startShowTime || null"
                :open="isOpen === `${index}-${'startTime'}`"
                @change="(e) => onChange(e, index)"
                @openChange="(e) => openChange(e, 'startTime', index)"
                @panelChange="
                  (e) => panelChange(e, 'startTime', authItem, index)
                "
              />
              <a-date-picker
                mode="year"
                placeholder="请选择结束年份"
                format="YYYY"
                style="width: 160px; margin-right: 10px"
                :value="authItem.endShowTime || null"
                :open="isOpen === `${index}-${'endTime'}`"
                @change="(e) => onChange(e, index)"
                @openChange="(e) => openChange(e, 'endTime', index)"
                @panelChange="(e) => panelChange(e, 'endTime', authItem, index)"
              />
              <a-input
                style="width: 280px"
                allowClear
                v-model="authItem.exhibitionHall"
                placeholder="请输入展览空间名称"
              />
              <a-input
                style="width: 270px; margin-left: 10px"
                allowClear
                v-model="authItem.adress"
                placeholder="请输入展览地址"
              />
            </div>
            <div
              class="flex-start"
              style="width: 900px; margin-bottom: 15px; flex-wrap: wrap"
            >
              <div
                v-for="(img, idx) in authItem.imgs"
                :key="img"
                class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0"
              >
                <img
                  v-if="img"
                  :src="`${$const.OssImagePrefix}/${img}`"
                  class="upload-btn-02"
                />
                <img
                  src="@/assets/delete.png"
                  class="img-delete"
                  @click="handleRemoveImage(index, idx)"
                />
              </div>
              <a-upload
                style="margin: 0 15px 15px 0"
                name="file"
                :multiple="false"
                :before-upload="handleBeforeUpload"
                @change="(e) => handleAuthImage(e, index)"
                :customRequest="handleCustomUpload"
                :showUploadList="false"
                v-if="!authItem.imgs || authItem.imgs.length < 100"
              >
                <a-button class="upload-btn-02">认证图片</a-button>
              </a-upload>
            </div>
          </div>
        </a-form-model-item>
        <a-button type="primary" class="ML12" @click="handleAddAuth"
          >新增展览</a-button
        >
      </template>
      <template title="店铺环境认证" v-if="authType === '10'">
        <a-form-model-item label="店铺环境认证">
          <div v-for="(authItem, index) in authFormData" :key="index">
            <div
              class="flex-start"
              style="
                width: 900px;
                margin-bottom: 15px;
                flex-wrap: wrap;
                display: none;
              "
            >
              <a-input
                style="width: 280px; margin-right: 15px"
                allowClear
                v-model="authItem.title"
                placeholder="请输入"
              />
            </div>
            <div
              class="flex-start"
              style="width: 900px; margin-bottom: 15px; flex-wrap: wrap"
            >
              <div
                v-for="(img, idx) in authItem.imgs"
                :key="img"
                class="upload-btn-02 img-wrap"
                style="margin: 0 15px 15px 0"
              >
                <img
                  v-if="img"
                  :src="`${$const.OssImagePrefix}/${img}`"
                  class="upload-btn-02"
                />
                <img
                  src="@/assets/delete.png"
                  class="img-delete"
                  @click="handleRemoveImage(index, idx)"
                />
              </div>
              <a-upload
                style="margin: 0 15px 15px 0"
                name="file"
                :multiple="false"
                :max-count="100"
                :before-upload="handleBeforeUpload"
                @change="(e) => handleAuthImage(e, index)"
                :customRequest="handleCustomUpload"
                :showUploadList="false"
                v-if="!authItem.imgs || authItem.imgs.length < 100"
              >
                <a-button class="upload-btn-02">店铺环境图</a-button>
              </a-upload>
            </div>
          </div>
        </a-form-model-item>
      </template>
      <a-form-model-item label="状态" prop="state">
        <a-radio-group v-model="formData.state">
          <a-radio :value="0">认证待审核</a-radio>
          <a-radio :value="1">已认证</a-radio>
          <a-radio :value="2">未通过认证</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import DetailTop from "./audit-top.vue";
import * as Settle from "@/api/user/settle";
import moment from "moment";
import { message } from "ant-design-vue";
import { uploadFile } from "@/components/AliOss/OssClient.js";
import txMap from "../../components/txmap";

export default {
  components: {
    DetailTop,
    txMap,
  },
  name: "insert",
  data() {
    return {
      authType: 1,
      userType: 1,
      fileUploadLimit: 0,
      id: "",
      userInfotop: {},
      userInfo: {},
      form: {
        uploadFileList: [],
      },
      formData: {
        setlleType: 0,

        intro: "", // 简介认证
        shopBrief: "", // 品牌故事
        shopName: undefined,
        enterpriseName: undefined,
        manageType: undefined,
        platform: 0,
        state: 1,
        shopAdress: "",
        shopAdressDetail: "",
        workDaysBegin: "周一",
        workDaysEnd: "周一",
        workTimeBegin: "",
        workTimeEnd: "",
      },
      idCards: {
        handIdentityCard: "",
        frontIdentityCard: "",
        backIdentityCard: "",
      },
      brandImages: {
        brandLogoPic: "",
        brandRegistPic: "",
        brandAuthorizePic: "",
      },
      authFormData: [
        {
          timePeriod: "",
          title: "",
          imgs: [],
        },
      ],
      rules: {
        realName: [
          {
            required: true,
            message: "请输入真实姓名",
            trigger: "blur",
          },
        ],
        shopBrief: [
          {
            required: true,
            message: "请输入简介",
            trigger: "blur",
          },
        ],
        setlleType: [
          {
            required: true,
            message: "请选择入驻类型",
            trigger: "change",
          },
        ],
        shopName: [
          {
            required: true,
            message: "请输入店铺名称",
            trigger: "blur",
          },
        ],
        shopAdressDetail: [
          {
            required: true,
            message: "请输入经营地址",
            trigger: "blur",
          },
        ],
        brandName: [
          {
            required: true,
            message: "请输入品牌名",
            trigger: "blur",
          },
        ],
        identityName: [
          {
            required: true,
            message: "请输入店铺名称",
            trigger: "blur",
          },
        ],
        enterpriseName: [
          {
            required: true,
            message: "请输入企业名称",
            trigger: "blur",
          },
        ],
        manageType: [
          {
            required: true,
            message: "请选择经营类型",
            trigger: "change",
          },
        ],
        platform: [
          {
            required: true,
            message: "请选择入驻平台",
            trigger: "change",
          },
        ],
      },
      isOpen: "",
      businessResp: {},
      personalResp: {},
      addrobj: {},
      isaddrobj: false,
      isedit: 0,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.isedit = this.$route.query.isedit || 0;
    this.bsId = this.$route.query.bsId;
    this.userId = this.$route.query.userId;
    this.userPhone = this.$route.query.userPhone;
    console.log(this.$route.query.userId, "this.$route.query.userId");
  },
  mounted() {
    this.authType = this.$route.query.authType;
    this.userType = this.$route.query.userType;
    if (this.isedit == 1) {
      this.$route.meta.title = "编辑认证";
      this.$forceUpdate();
      this.queryDetailById();
    } else {
      this.$route.meta.title = "新增认证";
      this.$forceUpdate();
      if (this.userType === "0") {
        this.getUserByPhone();
      } else {
        this.querySettleById();
      }
      this.isaddrobj = true;
    }
  },
  methods: {
    queryDetailById() {
      Settle.applyDetail({
        applyId: this.id,
        businessType: Number(this.userType),
      })
        .then((res) => {
          if (res.data) {
            const data = res.data || {};
            if (res.data.businessInfoResp) {
              this.formData = res.data || {};
              this.userInfo = data || {};
              this.businessResp = res.data.businessInfoResp || {};
              this.personalResp = res.data.personalResp || {};
              if (this.authType == 1) {
                this.idCards = data;
              } else if (this.authType == 2) {
                this.authFormData[0].imgs = data.pics;
                this.authFormData[0].title = data.identityName;
              } else if (this.authType == 5) {
                this.formData.brandName = data.brandName;
                this.brandImages.brandLogoPic = data.brandLogoPic;
                this.brandImages.brandRegistPic = data.brandRegistPic;
                this.brandImages.brandAuthorizePic = data.brandAuthorizePic;
              } else if (this.authType == 6) {
                this.formData.shopAdress = data.shopAdress;
                if (data.shopAdress) {
                  try {
                    this.addrobj = JSON.parse(data.shopAdress);
                  } catch (error) {
                    console.log(error);
                    this.addrobj = {
                      addr: data.shopAdress,
                      lat: data.latitude || 0,
                      lon: data.longitude || 0,
                    };
                  }
                }
                this.isaddrobj = true;
                this.formData.shopAdressDetail = data.shopAdressDetail;
                this.formData.workDaysBegin = data.workDaysBegin;
                this.formData.workDaysEnd = data.workDaysEnd;
                this.formData.workTimeBegin = data.workTimeBegin;
                this.formData.workTimeBegin = data.workTimeEnd;
                this.authFormData[0].imgs = data.pics;
              } else if (this.authType == 9) {
                this.authFormData[0].imgs = data.pics;
                this.authFormData[0].title = data.title;
              } else if (this.authType == 10) {
                this.authFormData[0].imgs = data.pics;
                this.authFormData[0].title = data.title;
              } else if (this.authType == 11) {
                let _data = data.exhibitionContentItem;
                this.exMap(_data);
              } else if (this.authType == 12) {
                let _data = data.experienceContentItem;
                this.exMap(_data);
              } else if (this.authType == 13) {
                let _data = data.awardContentItem;
                this.exMap(_data);
              }
              this.formData.state = data.state;
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    exMap(data) {
      this.authFormData = [];
      data.map((item) => {
        return this.authFormData.push({
          id: item.id,
          timePeriod: item.timePeriod,
          startShowTime: item.timePeriod
            ? item.timePeriod.split("-")[0].replace("年", "")
            : "",
          endShowTime: item.timePeriod
            ? item.timePeriod.split("-")[1].replace("年", "")
            : "",
          title: item.title,
          exhibitionHall: item.exhibitionHall,
          adress: item.adress,
          imgs: item.pics,
        });
      });
    },
    getUserByPhone() {
      Settle.queryHkyPersonal({ phone: this.userPhone })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.personalResp = res.data;
            console.log(this.personalResp, "===>this.personalResp");
          }
        })
        .catch((e) => {
          this.message = e.message || "查询错误";
        })
        .finally(() => {});
    },
    querySettleById() {
      // Settle.businessManageDeatil({
      //   businessTag: this.userType === '0' ? 0 : 1,
      //   businessType: this.userType,
      //   userId: this.id
      // }).then(res => {
      //   if (res.data) {
      //     const data = res.data || {}
      //     this.userInfo = data || {}
      //     this.businessResp = res.data.businessInfoResp || {}
      //     this.personalResp = res.data.personalResp || {}
      //   }
      // }).finally(() => {
      //   this.loading = false
      // })
      Settle.businessManageDeatilNew({
        businessUserId: this.bsId,
      })
        .then((res) => {
          if (res.data) {
            const data = res.data || {};
            this.userInfo = data || {};
            this.businessResp = res.data.businessInfoResp || {};
            this.personalResp = res.data.personalResp || {};
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit() {
      let isedit = this.isedit && this.isedit == 1 ? 1 : 0;
      let data = {
        // phone: this.userInfo.phone,
        businessUserId: this.bsId,
        businessTag: this.userType === "0" ? 0 : 1,
        businessType: Number(this.userType),
        type: Number(this.authType),
        state: this.formData.state,
      };
      if (isedit == 1) {
        data.id = parseInt(this.id);
      }
      if (this.userType === "0") {
        if (this.authType === "1") {
          data = {
            ...data,
            userId: this.id,
            realName: this.formData.realName,
            handIdentityCard: this.idCards.handIdentityCard,
            frontIdentityCard: this.idCards.frontIdentityCard,
            backIdentityCard: this.idCards.backIdentityCard,
          };
          Settle.personalreal(data).then((res) => {
            if (res.code === 0) {
              message.success("新增成功");
              this.$router.back();
            }
          });
        } else if (this.authType === "2") {
          const [authItem] = this.authFormData;
          if (authItem.title === "") {
            return message.error("请输入认证");
          }
          data = {
            ...data,
            userId: this.id,
            identityName: authItem.title,
            pics: authItem.imgs,
          };
          Settle.personalidentity(data).then((res) => {
            if (res.code === 0) {
              message.success("新增成功");
              this.$router.back();
            }
          });
        }
      } else if (this.userType === "1" || this.userType === "2") {
        if (this.authType === "1") {
          // nothing to do
        } else if (this.authType === "2") {
          const [authItem] = this.authFormData;
          if (authItem.title === "") {
            return message.error("请输入认证");
          }
          data = {
            ...data,
            identityName: authItem.title,
            idPics: authItem.imgs,
          };
        } else if (this.authType === "4") {
          if (this.formData.shopBrief === "") {
            return message.error("请输入品牌故事");
          }
          data = {
            ...data,
            shopBrief: this.formData.shopBrief,
          };
        } else if (this.authType === "14") {
          if (this.formData.shopName === "") {
            return message.error("请输入主页名称");
          }
          data = {
            ...data,
            shopName: this.formData.shopName,
          };
        } else if (this.authType === "15") {
          if (this.formData.intro === "") {
            return message.error("请输入认证简介");
          }
          data = {
            ...data,
            intro: this.formData.intro,
          };
        } else if (this.authType === "5") {
          if (this.formData.brandName === "") {
            return message.error("请输入商标名称");
          }
          data = {
            ...data,
            brandName: this.formData.brandName,
            brandLogoPic: this.brandImages.brandLogoPic,
            brandRegistPic: this.brandImages.brandRegistPic,
            brandAuthorizePic: this.brandImages.brandAuthorizePic,
          };
        } else if (this.authType === "6") {
          const [authItem] = this.authFormData;
          data = {
            ...data,
            shopAdress: this.formData.shopAdress,
            shopAdressDetail: this.formData.shopAdressDetail,
            workDaysBegin: this.formData.workDaysBegin,
            workDaysEnd: this.formData.workDaysEnd,
            workTimeBegin: this.formData.workTimeBegin,
            workTimeEnd: this.formData.workTimeEnd,
            contractPics: authItem.imgs,
          };
        } else if (this.authType === "9") {
          const [authItem] = this.authFormData;
          data = {
            ...data,
            environmentPics: authItem.imgs,
          };
        } else if (this.authType === "10") {
          const [authItem] = this.authFormData;
          data = {
            ...data,
            environmentPics: authItem.imgs,
          };
        } else if (this.authType === "11") {
          let errorMsg = "";
          const items = this.authFormData.map((item) => {
            if (!item.startShowTime) {
              errorMsg = "请选择开始年份";
            } else if (!item.endShowTime) {
              errorMsg = "请选择结束年份";
            } else if (!item.exhibitionHall) {
              errorMsg = "请输入空间名称";
            } else if (!item.adress) {
              errorMsg = "请输入展览地址";
            }
            return {
              id: item.id,
              timePeriod: `${item.startShowTime}年-${item.endShowTime}年`,
              exhibitionHall: item.exhibitionHall,
              pics: item.imgs,
              adress: item.adress,
            };
          });
          if (errorMsg) {
            return this.$message.error({
              content: errorMsg,
            });
          }
          data = {
            ...data,
            experienceContentList: items,
          };
        } else if (this.authType === "12") {
          let errorMsg = "";
          const items = this.authFormData.map((item) => {
            if (!item.startShowTime) {
              errorMsg = "请选择开始年份";
            } else if (!item.endShowTime) {
              errorMsg = "请选择结束年份";
            } else if (!item.title) {
              errorMsg = "请输入标题";
            }
            return {
              id: item.id,
              timePeriod: `${item.startShowTime}年-${item.endShowTime}年`,
              title: item.title,
              pics: item.imgs,
            };
          });
          if (errorMsg) {
            return this.$message.error({
              content: errorMsg,
            });
          }
          data = {
            ...data,
            experienceContentList: items,
          };
        } else if (this.authType === "13") {
          const items = this.authFormData.map((item) => ({
            title: item.title,
            pics: item.imgs,
          }));
          data = {
            ...data,
            experienceContentList: items,
          };
        }
        let that = this;
        Settle.authentication(1, this.authType, data, isedit).then((res) => {
          if (res.code === 0) {
            message.success(res.message);
            this.$router.back();
          }
        });
      }
    },
    handleCustomUpload(options) {
      const _file = options.file;
      const _fileType = _file.name
        .substring(_file.name.lastIndexOf(".") + 1)
        .toLowerCase();
      const _fileName = `${moment().format("YYYYMMDDHHmmss")}${Math.floor(
        Math.random() * 10 * 100000
      )}.${_fileType}`;
      uploadFile({
        bizType: "trend",
        type: "file",
        fileName: _fileName,
        targetName: new Date().getTime() + "_" + _fileName,
        file: _file,
      }).then((result) => {
        if (result.res.statusCode === 200) {
          options.onSuccess(result, options);
        } else {
          this.$message.error("上传失败");
        }
      });
    },
    handleChangeIdCard(info) {
      if (info.file.status === "done") {
        this.idCards.handIdentityCard = info.file.response.name;
      }
    },
    handleChangePositive(info) {
      if (info.file.status === "done") {
        this.idCards.frontIdentityCard = info.file.response.name;
      }
    },
    handleChangeOther(info) {
      if (info.file.status === "done") {
        this.idCards.backIdentityCard = info.file.response.name;
      }
    },
    handleBrandRegistPic(info) {
      if (info.file.status === "done") {
        this.brandImages.brandRegistPic = info.file.response.name;
      }
    },
    handleBrandLogoPic(info) {
      if (info.file.status === "done") {
        this.brandImages.brandLogoPic = info.file.response.name;
      }
    },
    handleBrandAuthorizePic(info) {
      if (info.file.status === "done") {
        this.brandImages.brandAuthorizePic = info.file.response.name;
      }
    },
    handleAuthImage(info, index) {
      if (info.file.status === "done") {
        this.authFormData[index].imgs.push(info.file.response.name);
      }
    },
    handleRemoveImage(index, idx) {
      this.authFormData[index].imgs.splice(idx, 1);
    },
    handleAddAuth() {
      this.authFormData = [
        ...this.authFormData,
        {
          timePeriod: "",
          title: "",
          imgs: [],
        },
      ];
    },
    handleBeforeUpload(file) {
      this.uploadTempFile = file;
      if (!file.name.match(/.jpg|.gif|.png|.jpeg|.bmp/i)) {
        this.$message.error("上传的图片格式不正确，请重新选择");
        return false;
      }
      return true;
    },
    focus() {},
    handleRemove() {},
    handlePreview() {},
    handleChange({ file, uploadFileList }) {
      let fileName = file.name;
      uploadFile({
        bizType: "image",
        type: "file",
        targetName: fileName,
        file: file,
      })
        .then((uploadResult) => {
          let fileResult = this.convertOssToUpload(uploadResult);
          if (this.form.uploadFileList && this.form.uploadFileList.length > 0) {
            this.form.uploadFileList = [
              ...this.form.uploadFileList,
              fileResult,
            ];
          } else {
            this.form.uploadFileList = [fileResult];
          }
        })
        .catch((err) => {
          this.$modal.alertError("上传处理失败" + err);
        });
    },
    handleMap(res) {
      this.addrobj = res;
      this.formData.shopAdress = JSON.stringify(res);
    },
    onChange() {
      this.isOpen = "";
    },
    // 使用openChange事件控制日期下拉面板显示与收起
    openChange(isOpen, type, index) {
      if (isOpen) {
        this.isOpen = `${index}-${type}`;
      } else {
        this.isOpen = "";
      }
    },
    panelChange(value, type, payload, index) {
      if (type === "startTime") {
        this.authFormData[index]["startShowTime"] =
          moment(value).format("YYYY");
      } else if (type === "endTime") {
        if (!payload.startShowTime) {
          return this.$message.error({
            content: "请先选择开始年份",
          });
        }
        const checkYear = moment(value).format("YYYY");
        if (parseInt(checkYear) <= parseInt(payload.startShowTime)) {
          return this.$message.error({
            content: "年份选择错误",
          });
        }
        this.authFormData[index]["endShowTime"] = checkYear;
      }
      this.isOpen = "";
    },

    limitInput(textarea) {
      console.log(textarea);
      // 限制字符数为 100
      const maxLength = 100;
      if (textarea.target.value.length > maxLength) {
        textarea.value = textarea.target.value.slice(0, maxLength);
      }

      // 限制行数为 4
      const maxRows = 4;
      const rows = textarea.target.value.split("\n").length;
      console.log(rows);
      if (rows > maxRows) {
        const lastIndex = textarea.target.value.lastIndexOf("\n");
        textarea.target.value = textarea.target.value.slice(0, lastIndex);
      }
      this.formData.intro = textarea.target.value;
    },
  },
};
</script>

<style scoped lang="less">
.container {
  padding: 20px;
  background: #ffffff;

  .label {
    width: 100px;
  }
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.margin-bottom {
  margin-bottom: 20px;
}

.upload-btn {
  width: 411px;
  height: 244px;
  font-weight: bold;
  font-size: 20px;
  background: #f2f2f2;
}

.upload-btn-02 {
  width: 285px;
  height: 244px;
  font-weight: bold;
  font-size: 20px;
  background: #f2f2f2;
}

.justify-start {
  display: flex;
  justify-content: flex-start;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

/deep/ .ant-form-item-required {
  font-size: 18px;
  font-weight: bold;
}

.img-wrap {
  position: relative;
}

.img-delete {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
</style>
