<template>
  <div class="txmapwrap">
    <div v-show="isclick">
      <a-auto-complete placeholder="输入地址搜索" @search="addrChange" @select="addrSelect">
        <template #dataSource>
          <a-select-option v-for="item in addrDatas" :key="item.id">
            {{ item.value }}
          </a-select-option>
        </template>
      </a-auto-complete>
    </div>
    <div id="container" style="width:680px;height:430px;margin-top: 10px"></div>
  </div>
</template>

<script>
  import * as Settle from "@/api/user/settle";
  export default {
    name: 'txmap',
    props: {
      lat: {
        type: Number,
        default: 39.916527
      },
      lng: {
        type: Number,
        default: 116.397128
      },
      isclick: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        addrDatas: [],
        markerInit: null,
        mapobj: null
      }
    },
    methods: {
      bindclick() {
        let that = this;
        if (that.isclick) {
          qq.maps.event.addListener(that.mapobj, 'click', function(event) {
            let lat = event.latLng.getLat();
            let lng = event.latLng.getLng();
            var location = new TMap.LatLng(lat, lng);
            if (that.markerInit) {
              that.markerInit.setMap(null);
            }
            that.markerInit = new qq.maps.Marker({
              position: event.latLng,
              map: that.mapobj
            });
            var geocoder = new TMap.service.Geocoder();
            geocoder.getAddress({
              location: location
            }).then((result) => {
              let param = {
                lng: lng,
                lat: lat,
                addr: ''
              }
              if (result.message.indexOf('ok') > -1) {
                param.addr = result.result.address;
              }
              that.$emit('end', param);
            });
          });
        }
      },
      resetmaker(obj) {
        var that = this;
        if (that.markerInit) {
          that.markerInit.setMap(null);
        }
        that.mapobj.setCenter(new qq.maps.LatLng(obj.lat, obj.lng));
        var myLatlng = new qq.maps.LatLng(obj.lat, obj.lng);
        that.markerInit = new qq.maps.Marker({
          position: myLatlng,
          map: that.mapobj
        });
      },
      addrChange(e) {
        let that = this;
        let key = e.trim();
        if (key) {
          Settle.getAddrList(key).then(res => {
            let data = [];
            if (res.data) {
              res.data.forEach(function(item) {
                data.push({
                  id: item.id,
                  value: item.address + item.title,
                  lat: item.location.lat,
                  lng: item.location.lng,
                })
              })
              that.addrDatas = data;
            }
          }).finally(() => {})
        }
      },
      addrSelect(e) {
        var single = this.addrDatas.filter(it => it.id == e)[0];
        if (single) {
          this.resetmaker(single);
          let param = {
            lat: single.lat,
            lng: single.lng,
            addr: single.value
          }
          this.$emit('end', param);
        }
      }
    },
    mounted() {
      let initLat = this.lat == 0 ? 39.916527 : this.lat;
      let initLng = this.lng == 0 ? 116.397128 : this.lng;
      // 定义工厂模式函数
      var myOptions = {
        zoom: 16, // 设置地图缩放级别
        center: new qq.maps.LatLng(initLat, initLng), // 设置中心点样式
        mapTypeId: qq.maps.MapTypeId.ROADMAP // 设置地图样式详情参见MapType
      }
      // 获取dom元素添加地图信息
      this.mapobj = new qq.maps.Map(document.getElementById("container"), myOptions);
      this.resetmaker({
        lat: initLat,
        lng: initLng
      });
      this.bindclick();
    }
  }
</script>
<style>
  .txmapwrap {
    position: relative;
  }

  .txmapwrap1 {
    position: absolute;
    display: flex;
    top: 0px;
    left: 0px;
    background-color: #fff;
    width: 200px;
    height: 50px;
  }

  .txmapwrap2 {
    width: 140px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-top: 10px;
    border: none;
  }

  .txmapwrap3 {
    width: 60px;
    background-color: #1890ff;
    color: #fff;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-top: 5px;
  }
</style>
